<script lang="ts">
import { defineComponent } from 'vue'
import { ApExportfile } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'ExportfileList',
	props: {
		exportfiles: {
			type: Array,
			required: true,
		},
	},
	emits: ['exportfile-selected'],
	methods: {
		formatDate (row: ApExportfile, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd / HH:mm')
		},
		rowClick (row: ApExportfile): void {
			window.open(row.url)
		},
	},
})
</script>

<template>
	<el-table :data="exportfiles" style="width: 100%" @row-click="rowClick">
		<el-table-column prop="export_date" :formatter="formatDate" label="Date" width="250"></el-table-column>
		<el-table-column prop="batch_type" label="Type" width="250"></el-table-column>
		<el-table-column prop="batch_number" label="Numéro" width="250"></el-table-column>
		<el-table-column prop="batch_name" label="Nom" width="250"></el-table-column>
	</el-table>
</template>
