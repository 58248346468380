
import { defineComponent } from 'vue'
import ExportfileList from '@/components/ExportfileList.vue'

export default defineComponent({
	name: 'ApExportfiles',
	components: {
		ExportfileList,
	},
	data () {
		return {
			errored: false,
			loading: false,
			exportfiles: [],
		}
	},
	mounted () {
		fetch(`${process.env.VUE_APP_API_URL}/exportfiles`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				id: this.$store.state.auth.customer.id,
			}),
		})
			.then(async (response: any) => {
				this.exportfiles = await response.json()
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		selectExportfile (id: string): void {
			this.$router.push({ name: 'exportfile', params: { id } })
		},
	},
})
