<script lang="ts">
import { defineComponent } from 'vue'
import ExportfileCard from '@/components/ExportfileCard.vue'
import { ApExportfile } from '@/types'

export default defineComponent({
	name: 'ApExportfile',
	components: {
		ExportfileCard,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		exportfile (): ApExportfile {
			return this.$store.getters.getExportfile(this.id)
		},
	},
})
</script>

<template>
	<exportfile-card :exportfile="exportfile"> </exportfile-card>
</template>

<style></style>
