
import { defineComponent } from 'vue'
import { ApExportfile } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'ExportfileList',
	props: {
		exportfiles: {
			type: Array,
			required: true,
		},
	},
	emits: ['exportfile-selected'],
	methods: {
		formatDate (row: ApExportfile, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd / HH:mm')
		},
		rowClick (row: ApExportfile): void {
			window.open(row.url)
		},
	},
})
